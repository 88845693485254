import React, { useEffect, useState } from "react";
import { Briefcase } from "react-feather";
import HolidayDiary from "../_components/HolidayDiary";
import HolidayStatus from "../_components/HolidayStatus";
import HolidayApplication from "../_components/HolidayApplication";

function CalendarPage() {
  return (
    <div className="px-4 py-5">
      <div className="row col-12">
        <div className="center text-center">
          <h1>Calendar</h1>
        </div>
        <hr className="my-4" />

        <div className="col-5">
          <h2>Holidays</h2>
          <div className="mt-3">
            <HolidayDiary />
          </div>
        </div>

        <div className="col-6">
          <h2>
            Apply for Holiday <Briefcase width="32px" height="32px" />
          </h2>
          <div className="mt-3">
            <HolidayApplication />
          </div>
          <h2 className="mt-4">Application Status</h2>
          <div className="mt-3">
            <HolidayStatus />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarPage;
