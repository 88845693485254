import React from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";

const center = {
  lat: 51.17596,
  lng: -0.15705,
};

// Array for each driver
// an array of locations
// which has an array with the lat and lng in it as well as the name

const drivers = [
  {
    locations: [
      { latlng: { lat: -31.56391, lng: 147.154312 }, name: "name" },
      { latlng: { lat: -33.718234, lng: 150.363181 }, name: "latlng" },
      { latlng: { lat: -33.727111, lng: 150.371124 }, name: "latlng" },
      { latlng: { lat: -33.848588, lng: 151.209834 }, name: "latlng" },
      { latlng: { lat: -33.851702, lng: 151.216968 }, name: "latlng" },
      { latlng: { lat: -34.671264, lng: 150.863657 }, name: "latlng" },
      { latlng: { lat: -35.304724, lng: 148.662905 }, name: "latlng" },
      { latlng: { lat: -36.817685, lng: 175.699196 }, name: "latlng" },
    ],
    name: "Darren",
  },

  {
    locations: [
      { latlng: { lat: -31.56391, lng: 147.154312 }, name: "name" },
      { latlng: { lat: -37.765015, lng: 145.133858 }, name: "latlng" },
      { latlng: { lat: -37.770104, lng: 145.143299 }, name: "latlng" },
      { latlng: { lat: -37.7737, lng: 145.145187 }, name: "latlng" },
      { latlng: { lat: -37.774785, lng: 145.137978 }, name: "latlng" },
      { latlng: { lat: -37.819616, lng: 144.968119 }, name: "latlng" },
      { latlng: { lat: -38.330766, lng: 144.695692 }, name: "latlng" },
      { latlng: { lat: -36.817685, lng: 175.699196 }, name: "latlng" },
      { latlng: { lat: -36.828611, lng: 175.790222 }, name: "latlng" },
      { latlng: { lat: -37.75, lng: 145.116667 }, name: "latlng" },
    ],
    name: "Terry",
  },

  {
    locations: [
      { latlng: { lat: -37.759859, lng: 145.128708 }, name: "latlng" },
      { latlng: { lat: -39.927193, lng: 175.053218 }, name: "latlng" },
      { latlng: { lat: -41.330162, lng: 174.865694 }, name: "latlng" },
      { latlng: { lat: -42.734358, lng: 147.439506 }, name: "latlng" },
      { latlng: { lat: -42.734358, lng: 147.501315 }, name: "latlng" },
      { latlng: { lat: -42.735258, lng: 147.438 }, name: "latlng" },
      { latlng: { lat: -43.999792, lng: 170.463352 }, name: "latlng" },
    ],
    name: "Ryan",
  },
];

const clusterOptions = {
  imagepath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
};

function createKey(location) {
  return location.lat + location.lng;
}

function Maps() {
  return (
    <LoadScript googleMapsApiKey="AIzaSyDd4a4FyYkL9zHbOFACoBKd6RJl81YGEEM">
      <GoogleMap
        mapContainerClassName="col-10"
        // mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <MarkerClusterer options={clusterOptions}>
          {(clusterer) =>
            drivers.map((driver) =>
              driver.locations.map((location) => (
                <Marker
                  key={createKey(location.latlng)}
                  position={location.latlng}
                  title={location.name + driver.name}
                  clusterer={clusterer}
                />
              ))
            )
          }
        </MarkerClusterer>
        {/* <Marker position="" clickable="false" label="" icon="http://maps.google.com/mapfiles/ms/icons/red-dot.png" /> */}
        <></>
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(Maps);
