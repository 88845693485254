import React, { useEffect, useState } from "react";
import { HashRouter as Router, Link, Route } from "react-router-dom";

import { history } from "./_helpers/history";
import { Role } from "./_helpers/role";
import { authenticationService } from "./_services/authentication.service";
import { PrivateRoute } from "./_components/PrivateRoute";

// Pages
import Admin from "./pages/Admin";
import HomePage from "./pages/Home";
import Login from "./pages/Login";
import RequestForm from "./pages/RequestForm";
import Reset from "./pages/Reset";
import SettingPage from "./pages/Settings";
import CalendarPage from "./pages/Calendar";

// Search Pages
import Status from "./pages/search/Search";
import Reference from "./pages/search/Reference";
import Policy from "./pages/search/Policy";

// Field Service Module
import JobScreen from "./pages/field-service/JobScreen";
import Diary from "./pages/field-service/Diary";
import Summary from "./pages/field-service/Summary";
import Arrive from "./pages/field-service/Arrive";
import DriverMaps from "./pages/field-service/Maps";

// Portal Module
import RepairRequest from "./pages/portal/RepairRequestForm";
import RepairHomePage from "./pages/portal/RepairHomePage";
import Job from "./pages/portal/Job";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import {
  Search,
  BookOpen,
  Calendar,
  File,
  Package,
  Truck,
  Users,
  Archive,
  Settings,
} from "react-feather";

import Logo from "./img/SuperBaseAlpha.png";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentJob] = useState(localStorage.getItem("currentjob"));

  useEffect(() => {
    authenticationService.currentUser.subscribe((x) => {
      // setCurrentUser(x);
      setLoggedIn(x && false);
      setIsAdmin(x && localStorage.getItem("role") === Role.Admin);
    });
  });

  //   function logout() {
  //     authenticationService.logout();
  //     history.push("/#/login");
  //   }

  return (
    <>
      <Router>
        {loggedIn !== false ? (
          <div className="">
            <Route exact path="/" component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/reset" component={Reset} />
            <Route path="/portal" component={RepairHomePage} />
            <Route path="/create-request" component={RepairRequest} />
          </div>
        ) : (
          <>
            <nav className="bg-light sidebar">
              <ul className="sidebar-nav">
                <li>
                  <Link to="/" className="nav-link">
                    <img className="img-fluid logo" src={Logo} alt="Logo" />
                  </Link>
                </li>

                <li className="sidebar-item">
                  <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mb-1 text-muted">
                    <span className="nav-text">Office</span>
                  </h6>

                  <Link
                    to="/search"
                    aria-label="Search Jobs"
                    className="nav-link"
                  >
                    <Search className="sidebar-icon" />
                    <span className="nav-text">Search Jobs</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/spares" aria-label="Spares" className="nav-link">
                    <Package className="sidebar-icon" />
                    <span className="nav-text">Spares</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link
                    to="/van"
                    aria-label="Field Van Runs"
                    className="nav-link"
                  >
                    <Truck className="sidebar-icon" />
                    <span className="nav-text">Van Runs</span>
                  </Link>
                </li>

                <li className="sidebar-item">
                  <hr className="" />

                  <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mb-1 text-muted">
                    <span className="nav-text">Field Service</span>
                  </h6>
                  <Link
                    to="/summary"
                    aria-label="Field Service Daily Summary"
                    className="nav-link"
                  >
                    <BookOpen className="sidebar-icon" />
                    {"  "}
                    <span className="nav-text">Daily Summary</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link
                    to="/diary"
                    aria-label="Field Service Driver Diary"
                    className="nav-link"
                  >
                    <Calendar className="sidebar-icon" />
                    {"  "}
                    <span className="nav-text">Diary</span>
                  </Link>
                  <hr />
                </li>

                <li className="sidebar-item">
                  {isAdmin === false && (
                    <>
                      <h6 className="sidebar-heading justify-content-between align-items-center px-3 mb-1 text-muted">
                        <span className="nav-text">Admin</span>
                      </h6>

                      <li className="sidebar-item">
                        <Link
                          to="/users"
                          aria-label="Users"
                          className="nav-link"
                        >
                          <Users className="sidebar-icon" />
                          {"  "}
                          <span className="nav-text">Users</span>
                        </Link>
                      </li>
                      <li className="sidebar-item">
                        <Link to="/jobs" aria-label="Jobs" className="nav-link">
                          <Archive className="sidebar-icon" />
                          {"  "}
                          <span className="nav-text">Jobs</span>
                        </Link>
                      </li>
                      <li className="sidebar-item">
                        <Link
                          to="/reports"
                          aria-label="Reports"
                          className="nav-link"
                        >
                          <File className="sidebar-icon" />
                          {"  "}
                          <span className="nav-text">Reports</span>
                        </Link>
                      </li>
                      <hr className="" />
                    </>
                  )}
                  <div className="row mb-2">
                    <img
                      src={Logo}
                      alt="User Profile"
                      className="sidebar-avatar"
                    />
                    <div className="col-7 sidebar-user">
                      <p className="sidebar-user-name">Nikolaus Zolnhofer</p>
                      <p className="sidebar-user-email">nikolaus@simpol.com</p>
                    </div>
                    <Link
                      to="/settings"
                      aria-label="Settings"
                      className="nav-link settings-col"
                    >
                      <Settings className="sidebar-icon"></Settings>
                    </Link>
                  </div>
                </li>
              </ul>
            </nav>
            <main>
              {/* Open Routes */}
              {/* Default Routes */}
              <Route exact path="/" component={HomePage} />
              <PrivateRoute
                path="/admin"
                roles={[Role.Admin]}
                component={Admin}
              />
              <PrivateRoute path="/settings" component={SettingPage} />
              <PrivateRoute path="/calendar" component={CalendarPage} />
              {/* Amazon Specific */}
              <PrivateRoute path="/request" component={RequestForm} />
              {/* Job Search */}
              <PrivateRoute path="/search" component={Status} />
              <PrivateRoute path="/policy/:id" component={Policy} />
              <PrivateRoute path="/reference/:id" component={Reference} />
              
              {/* Field Service */}
              <PrivateRoute path="/diary" component={Diary} />
              <PrivateRoute path="/field/:id" component={JobScreen} />
              <Route path="/arrive/:id" component={Arrive} />  
              <PrivateRoute path="/summary" component={Summary} />
              <PrivateRoute path="/maps" component={DriverMaps} />

              {/* Portal */}
              <PrivateRoute path="/jobs/:id" component={Job} />

              {currentJob && history.push(`/#/arrive/${currentJob}`)}
            </main>
          </>
        )}
      </Router>
    </>
  );
}
export default App;
