import React from "react";

const JobList = (props) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Repair Reference</th>
          <th>Policy No.</th>
          <th>Date Added</th>
          <th>Product Type</th>
          <th>Job Status</th>
          <th>Expected Dispatch Date</th>
          <th>Additonal Info</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((item) => {
          return (
            <tr>
              <td>{item["repair-reference"]}</td>
              <td>{item["policy-no"]}</td>
              <td>{item["file-date"]}</td>
              <td>{item["product-type"]}</td>
              <td>{item["job-status"]}</td>
              <td>{item["status-date"]}</td>
              <td>{item["spare-text3"]}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default JobList;
