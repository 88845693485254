import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ROOT_API = process.env.REACT_APP_API_URL;

function Diary() {
  const [errorMsg, setErrorMsg] = useState("Loading...");
  const [isLoadingJobs, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const data = await fetch(`${ROOT_API}/field`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Accept: "application/json; charset=UTF-8",
            Connection: "close",
            "session-id": JSON.parse(localStorage.getItem("session")),
          },
        });
        const dataJSON = await data.json();
        if (dataJSON) {
          setData(dataJSON.data.jobs);
          setIsLoading(false);
        }
      } catch (error) {
        setErrorMsg(error.message);
      }
    };
    fetchCount();
  }, [isLoadingJobs]);

  return (
    <div className="px-4 py-5">
      <div className="row col-12 center">
        <div className="center text-center">
          <h1>Diary</h1>
        </div>
        <hr className="" />
      </div>
      <div>
        {isLoadingJobs ? (
          <div className="container">
            <div className="row row-content">
              <h1 className="mt-3 mb-3">Diary for Driver</h1>

              <table className="table table-condensed mt-3">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Job No.</th>
                    <th>Post Code</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="placeholder-table">
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            {errorMsg}
          </div>
        ) : (
          <div className="">
            <div className="row row-content">
              <table className="table table-collapse table-condensed mt-3">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Job No.</th>
                    <th>Post Code</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    if (i === 0) {
                      return (
                        <tr>
                          <td>{i}</td>
                          <td>{item["call-date"]}</td>
                          <td>{item["job-no"]}</td>
                          <td>{item["post-code"]}</td>
                          <td>
                            <Link to={`/field/${item["job-no"]}`}>
                              Job Details
                            </Link>
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td>{i}</td>
                          <td>{item["call-date"]}</td>
                          <td>{item["job-no"]}</td>
                          <td>{item["post-code"]}</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Diary;
