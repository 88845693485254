import { BehaviorSubject } from "rxjs";

import { handleResponse } from "../_helpers/handle-response";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("session"))
);

const API_URL = process.env.REACT_APP_API_URL;

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

async function login(login, password) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Accept: "application/json; charset=UTF-8",
      Connection: "close",
    },
    body: JSON.stringify({ login, password }),
  };

  const response = await fetch(`${API_URL}/login`, requestOptions);
  const user = await handleResponse(response);

  if (user["error-code"] === 303) {
    return user;
  }
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  localStorage.setItem("session", JSON.stringify(user["session-id"]));
  localStorage.setItem("role", JSON.stringify(user["role"]));

  const date = new Date().getTime();
  localStorage.setItem("loginTime", date);
  currentUserSubject.next(user);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("session");
  localStorage.removeItem("role");
  currentUserSubject.next(null);
}
