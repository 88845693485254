import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Popup from "../../_components/Popup";
import SignaturePad from "react-signature-pad-wrapper";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const ROOT_API = process.env.REACT_APP_API_URL;

function Arrive() {
  const [isLoadingJob, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [jobNo, setJobNo] = useState("");
  const [address, setAddress] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [fault, setFault] = useState("");
  const [spares, setSpares] = useState([]);
  const [repairType, setRepairType] = useState("Inspection");
  const [customerName, setCustomerName] = useState("");
  const [contactNo, setContactNo] = useState("");

  const [signature, setSignature] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [opened, setOpened] = useState(false);

  var { id } = useParams();
  var { input } = useRef();
  var sigCanvas = useRef("");

  function clear() {
    sigCanvas.current.clear();
  }

  function save() {
    var sig = sigCanvas.current.toDataURL("image/png");
    sig = sig.slice(sig.indexOf(",") + 1);
    setSignature(sig);
    togglePopup();
  }

  async function customerNotIn() {
    fetch(`${ROOT_API}/field/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        status: "NOTPRESENT",
      }),
      headers: {
        Connection: "close",
        "session-id": JSON.parse(localStorage.getItem("session")),
      },
    });
    localStorage.removeItem("currentjob");
    window.location = "/#/diary";
  }

  async function handleFile(document) {
    const formData = new FormData();
    formData.append("file", document.files[0]);

    try {
      const data = await fetch(`${ROOT_API}/field/${id}`, {
        method: "PATCH",
        body: formData,
        headers: {
          "session-id": JSON.parse(localStorage.getItem("session")),
        },
      });
      var dataJSON = await data.json();
      if (dataJSON["error-code"] === 404) {
        setErrorMsg(dataJSON["error-message"]);
      } else {
        console.log("File Uploaded");
      }
    } catch (error) {
      setErrorMsg(error);
    }
  }

  useEffect(() => {
    const markArrived = async () => {
      try {
        var fd = new URLSearchParams();
        fd.append("status", "ARRIVED");
        const data = await fetch(`${ROOT_API}/field/${id}`, {
          method: "PATCH",
          body: fd,
          headers: {
            Connection: "close",
            "session-id": JSON.parse(localStorage.getItem("session")),
          },
        });
        var dataJSON = await data.json();
        if (dataJSON["error-code"] === 404) {
          setErrorMsg(dataJSON["error-message"]);
        } else {
          localStorage.setItem("currentjob", id);
        }
      } catch (error) {
        setErrorMsg(error);
      }
    };

    async function fetchJob() {
      try {
        const data = await fetch(`${ROOT_API}/field/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Accept: "application/json; charset=UTF-8",
            Connection: "close",
            "session-id": JSON.parse(localStorage.getItem("session")),
          },
        });
        var dataJSON = await data.json();
        if (dataJSON["error-code"] === 404) {
          setErrorMsg(dataJSON["error-message"]);
        } else {
          dataJSON = dataJSON.data;
          setJobNo(dataJSON["job-no"]);
          setAddress(dataJSON.address1);
          setMake(dataJSON.make);
          setModel(dataJSON.model);
          setFault(dataJSON.fault);
          setRepairType(dataJSON["service-type"]);
          setCustomerName(dataJSON["customer-name"]);
          setContactNo(dataJSON.mobile);
          setSpares(dataJSON.spares);
          setIsLoading(false);
        }
      } catch (error) {
        setErrorMsg(error);
      }
    }
    fetchJob();
    markArrived();
  }, [id, isLoadingJob]);

  const togglePopup = () => {
    setIsOpen(!isOpen);
    setOpened(true);
  };

  return (
    <div className="px-4 py-5">
      <div className="row col-12 center">
        <div className="center text-center">
          <h1>Job Details</h1>
        </div>
        <hr className="" />
      </div>
      <div>
        <div className="row col-12">
          {isLoadingJob ? (
            <div>{errorMsg}</div>
          ) : (
            <div>
              <h3>Job Details</h3>
              <p>
                <b>Job No: </b>
                {jobNo}
              </p>
              <p>
                <b>Address: </b>
                {address}
              </p>
              <h3>Model</h3>
              <p>
                <b>Make: </b>
                {make}
              </p>
              <p>
                <b>Model: </b>
                {model}
              </p>
              <p>
                <b>Fault: </b>
                {fault}
              </p>
              <p>
                <b>Repair Type: </b>
                {repairType}
              </p>
              <h3>Customer Details</h3>
              <p>
                <b>Customer Name: </b>
                {customerName}
              </p>
              <p>
                <b>Contact No: </b>
                {contactNo}
              </p>

              <Formik
                initialValues={{
                  pcb: false,
                  display: false,
                  psu: false,
                  other: false,
                  othertext: "",
                  report: "",
                  signature: "",
                }}
                validationSchema={Yup.object({
                  report: Yup.string().required("A report is required"),
                  othertext: Yup.string().when("other", {
                    is: true,
                    then: Yup.string().required(),
                  }),
                })}
                onSubmit={async (values) => {
                  var fd = new FormData();
                  fd.append("report", values.report);
                  fd.append("status", "COMPLETE");
                  if (values.pcb) {
                    fd.append("RequirePCB", true);
                  }
                  if (values.psu) {
                    fd.append("RequirePSU", true);
                  }
                  if (values.display) {
                    fd.append("RequireDisplay", true);
                  }
                  if (values.other) {
                    fd.append("Other", values.othertext);
                  }
                  fd.append("signature", signature.replace(" ", "+"));
                  fetch(`${ROOT_API}/field/${id}`, {
                    method: "PATCH",
                    body: fd,
                  });
                  localStorage.removeItem("currentjob");
                  window.location = "/#/diary";
                }}
              >
                {(props) => (
                  <Form>
                    {/* Fit Parts */}
                    {repairType === "Fit Parts" && (
                      <div>
                        <h3>Spares</h3>
                        <div className="table">
                          <table>
                            <thead>
                              <tr>
                                <th>Part Name</th>
                                <th>Email</th>
                              </tr>
                            </thead>
                            <tbody>
                              {spares.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.name}</td>
                                    <td>{item["mail-address"]}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}

                    {/* Inspection Report */}
                    {repairType === "Inspection" && (
                      <div>
                        <h3>Spares to Order</h3>
                        <div className="form-check">
                          <Field
                            name="pcb"
                            className="form-check-input"
                            type="checkbox"
                            id="pcb"
                          />
                          <label className="form-check-label" htmlFor="pcb">
                            Main PCB
                          </label>
                          <ErrorMessage
                            component="div"
                            className="text-danger"
                            name="pcb"
                          />
                        </div>
                        <div className="form-check">
                          <Field
                            name="display"
                            className="form-check-input"
                            type="checkbox"
                            id="display"
                          />
                          <label className="form-check-label" htmlFor="display">
                            Display / Panel
                          </label>
                          <ErrorMessage
                            component="div"
                            className="text-danger"
                            name="display"
                          />
                        </div>
                        <div className="form-check">
                          <Field
                            name="psu"
                            className="form-check-input"
                            type="checkbox"
                            id="psu"
                          />
                          <label className="form-check-label" htmlFor="psu">
                            Power Supply
                          </label>
                          <ErrorMessage
                            component="div"
                            className="text-danger"
                            name="psu"
                          />
                        </div>
                        <div className="form-check">
                          <Field
                            name="other"
                            className="form-check-input "
                            type="checkbox"
                            id="other"
                          />
                          <label className="form-check-label" htmlFor="other">
                            Other
                          </label>
                          <ErrorMessage
                            component="div"
                            className="text-danger"
                            name="other"
                          />
                        </div>
                        <div className="form-input">
                          <label
                            className="form-check-label"
                            htmlFor="othertext"
                          >
                            Other Please Specify
                          </label>
                          <Field
                            className="form-input"
                            type="text"
                            name="othertext"
                            id="othertext"
                          />
                          <ErrorMessage
                            component="div"
                            className="text-danger"
                            name="othertext"
                          />
                        </div>
                      </div>
                    )}

                    <h3>Engineer Report</h3>
                    <Field
                      name="report"
                      className="form-control"
                      as="textarea"
                      id="report"
                      rows="4"
                      cols="50"
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger"
                      name="report"
                    />

                    {opened && (
                      <button type="submit" className="btn btn-primary mt-3">
                        Submit
                      </button>
                    )}
                  </Form>
                )}
              </Formik>

              <label className="btn btn-default col-12 col-lg-4 p-0 mb-3 py-3">
                <input
                  type="file"
                  accept="image/*"
                  capture="environment"
                  reference={input}
                  multiple
                  id="input"
                  onChange={(e) => handleFile(e.target)}
                  className="form-control "
                />
              </label>

              {!opened && (
                <div className="row">
                  <button
                    id="signature"
                    type="button"
                    className="btn btn-primary col-12 col-lg-2 m-1 "
                    onClick={togglePopup}
                  >
                    Signature
                  </button>

                  <button
                    id="signature"
                    type="button"
                    className="btn btn-primary col-12 col-lg-2 m-1"
                    onClick={customerNotIn}
                  >
                    Not In
                  </button>
                </div>
              )}

              {isOpen && (
                <>
                  <Popup
                    id="signature"
                    content={
                      <>
                        <SignaturePad ref={sigCanvas} />
                        <button onClick={save} className="btn btn-primary">
                          Save
                        </button>
                        <button onClick={clear} className="btn btn-secondary">
                          Clear
                        </button>
                      </>
                    }
                    handleClose={togglePopup}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Arrive;
