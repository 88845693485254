import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const API_URL = process.env.REACT_APP_API_URL;

const HolidayApplication = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setIsError] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          date: "",
          reason: "",
          details: "",
        }}
        validationSchema={Yup.object({
          date: Yup.string().required("Required Value"),
          reason: Yup.string().required("Required Value"),
          details: Yup.string(),
        })}
        onSubmit={async (values) => {
          fetch(`${API_URL}/jobs`, {
            method: "POST",
            body: JSON.stringify({
              "policy-no": values.policyNo
                .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                .replace(/(")/gm, "'"),
            }),
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Accept: "application/json; charset=UTF-8",
              Connection: "close",
              "session-id": JSON.parse(localStorage.getItem("session")),
            },
          }).then((result) => {
            if (result.status === 201) {
              return result.json().then((json) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                values.policyNo = "";
              });
            } else {
              setIsError(true);
              setErrorMsg(result.json()["error-message"]);
            }
          });
        }}
      >
        <Form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="date">
                Select Day <strong className="text-danger">*</strong>
              </label>
              <Field
                name="date"
                className="form-control border"
                placeholder=""
                type="date"
              />
              <ErrorMessage
                component="div"
                className="text-danger"
                name="date"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="reason">
                Select Reason <strong className="text-danger">*</strong>
              </label>
              <Field name="reason" as="select" className="form-control border">
                <option defaultValue value="">
                  Select a Reason
                </option>
                <option value="camera">Camera</option>
                <option value="headphones">Headphones</option>
                <option value="tv">TV</option>
                <option value="other">Other</option>
              </Field>
              <ErrorMessage
                component="div"
                className="text-danger"
                name="reason"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-12 col-md-8 mb-3">
              <label htmlFor="details">Any other details</label>
              <Field
                name="details"
                className="form-control border"
                as="textarea"
                placeholder=""
                type="details"
              />
              <ErrorMessage
                component="div"
                className="text-danger"
                name="details"
              />
            </div>
          </div>
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </Form>
      </Formik>
    </>
  );
};

export default HolidayApplication;
