import React from "react";
import { Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Wizard, WizardStep } from "../../_components/Wizard";

function RepairHomePage() {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://www.dkavs.co.uk/wp-content/cache/minify/0a800.css"
      />
      <link rel="dns-prefetch" href="//www.dkavs.co.uk" />
      <div
        className="nav-search-enabled header-aligned-center dropdown-hover"
        itemType="https://schema.org/WebPage"
        itemScope
      >
        <a
          className="screen-reader-text skip-link"
          href="#content"
          title="Skip to content"
        >
          Skip to content
        </a>
        <header
          className="site-header"
          id="masthead"
          aria-label="Site"
          itemType="https://schema.org/WPHeader"
          itemScope
        >
          <div className="inside-header grid-container">
            <div className="site-logo">
              <a href="https://www.dkavs.co.uk/" title="DK AVS Ltd" rel="home">
                <img
                  className="header-image is-logo-image lazy"
                  alt="DK AVS Ltd"
                  src="https://www.dkavs.co.uk/wp-content/uploads/2016/06/cropped-cropped-dkavs-logo-new-1.png"
                  title="DK AVS Ltd"
                  width="512"
                  height="363"
                />
              </a>
            </div>
          </div>
        </header>
        <nav
          className="main-navigation nav-align-center"
          id="site-navigation"
          aria-label="Primary"
          itemType="https://schema.org/SiteNavigationElement"
          itemScope
        >
          <div className="inside-navigation">
            {/* <form
              method="get"
              className="search-form navigation-search"
              action="https://www.dkavs.co.uk"
            >
              <input
                type="search"
                className="search-field value name=s"
                title="Search"
              />
            </form> */}
            <button
              className="menu-toggle"
              aria-controls="primary-menu"
              aria-expanded="false"
            >
              <span className="gp-icon icon-menu-bars">
                <svg
                  viewBox="0 0 512 512"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                >
                  <path d="M0 96c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24zm0 160c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24zm0 160c0-13.255 10.745-24 24-24h464c13.255 0 24 10.745 24 24s-10.745 24-24 24H24c-13.255 0-24-10.745-24-24z" />
                </svg>
                <svg
                  viewBox="0 0 512 512"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                >
                  <path d="M71.029 71.029c9.373-9.372 24.569-9.372 33.942 0L256 222.059l151.029-151.03c9.373-9.372 24.569-9.372 33.942 0 9.372 9.373 9.372 24.569 0 33.942L289.941 256l151.03 151.029c9.372 9.373 9.372 24.569 0 33.942-9.373 9.372-24.569 9.372-33.942 0L256 289.941l-151.029 151.03c-9.373 9.372-24.569 9.372-33.942 0-9.372-9.373-9.372-24.569 0-33.942L222.059 256 71.029 104.971c-9.372-9.373-9.372-24.569 0-33.942z" />
                </svg>
              </span>
              <span className="mobile-menu">Menu</span>
            </button>
            <div id="primary-menu" className="main-nav">
              <ul id="menu-main-menu" className="menu">
                <li id="menu-item-1057" className="menu-item">
                  <a href="https://www.dkavs.co.uk/" aria-current="page">
                    Home
                  </a>
                </li>
                <li id="menu-item-645" className="menu-item current-menu-item">
                  <a href="https://repair.dkavs.co.uk" aria-current="page">
                    Repair Centre
                  </a>
                </li>
                <li id="menu-item-1033" className="menu-item">
                  <a href="https://www.dkavs.co.uk/domestic-general">
                    Domestic and General
                  </a>
                </li>
                <li id="menu-item-1034" className="menu-item">
                  <a href="https://www.dkavs.co.uk/lumix-lens-repair">
                    Lumix Lens Repair
                  </a>
                </li>
                <li id="menu-item-1245" className="menu-item">
                  <a href="https://www.dkavs.co.uk/about-us">About Us</a>
                </li>
                <li id="menu-item-532" className="menu-item">
                  <a href="https://www.dkavs.co.uk/contact-us">Contact Us</a>
                </li>
                <li id="menu-item-147" className="menu-item">
                  <a href="https://www.dkavs.co.uk/customer-feedback">
                    Feedback
                  </a>
                </li>
              </ul>
            </div>
            <div className="menu-bar-items">
              <span className="menu-bar-item search-item">
                <a aria-label="Open Search Bar" href="#">
                  <span className="gp-icon icon-search">
                    <svg
                      viewBox="0 0 512 512"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M208 48c-88.366 0-160 71.634-160 160s71.634 160 160 160 160-71.634 160-160S296.366 48 208 48zM0 208C0 93.125 93.125 0 208 0s208 93.125 208 208c0 48.741-16.765 93.566-44.843 129.024l133.826 134.018c9.366 9.379 9.355 24.575-.025 33.941-9.379 9.366-24.575 9.355-33.941-.025L337.238 370.987C301.747 399.167 256.839 416 208 416 93.125 416 0 322.875 0 208z"
                      />
                    </svg>
                    <svg
                      viewBox="0 0 512 512"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                    >
                      <path d="M71.029 71.029c9.373-9.372 24.569-9.372 33.942 0L256 222.059l151.029-151.03c9.373-9.372 24.569-9.372 33.942 0 9.372 9.373 9.372 24.569 0 33.942L289.941 256l151.03 151.029c9.372 9.373 9.372 24.569 0 33.942-9.373 9.372-24.569 9.372-33.942 0L256 289.941l-151.029 151.03c-9.373 9.372-24.569 9.372-33.942 0-9.372-9.373-9.372-24.569 0-33.942L222.059 256 71.029 104.971c-9.372-9.373-9.372-24.569 0-33.942z" />
                    </svg>
                  </span>
                </a>
              </span>
            </div>
          </div>
        </nav>
        <div
          style={{
            display: "flex",
            padding: "0% 8% 0% 8%",
            width: "100%",
            maxWidth: "1140px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            className="sub-content"
            style={{ padding: "10px", alignSelf: "center", width: "100%" }}
          >
            <h1>Customer Portal</h1>

            <div
              style={{
                display: "flex",
                padding: "0 40px",
                marginLeft: "-40px",
                textAlign: "center",
              }}
              className="columns"
            >
              <div>
                <h1>Formik Multistep Wizard</h1>
                <Wizard
                  initialValues={{
                    email: "",
                    firstName: "",
                    lastName: "",
                  }}
                  onSubmit={
                    async (values) => console.log("Submit")
                    //   sleep(300).then(() => console.log('Wizard submit', values))
                  }
                >
                  <WizardStep
                    onSubmit={() => console.log("Step1 onSubmit")}
                    validationSchema={Yup.object({
                      firstName: Yup.string().required("required"),
                      lastName: Yup.string().required("required"),
                    })}
                  >
                    <div>
                      <label htmlFor="firstName">First Name</label>
                      <Field
                        autoComplete="given-name"
                        component="input"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        type="text"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="firstName"
                      />
                    </div>
                    <div>
                      <label htmlFor="lastName">Last Name</label>
                      <Field
                        autoComplete="family-name"
                        component="input"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        type="text"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="lastName"
                      />
                    </div>
                  </WizardStep>
                  <WizardStep
                    onSubmit={() => console.log("Step2 onSubmit")}
                    validationSchema={Yup.object({
                      email: Yup.string()
                        .email("Invalid email address")
                        .required("required"),
                    })}
                  >
                    <div>
                      <label htmlFor="email">Email</label>
                      <Field
                        autoComplete="email"
                        component="input"
                        id="email"
                        name="email"
                        placeholder="Email"
                        type="text"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="email"
                      />
                    </div>
                  </WizardStep>
                </Wizard>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-footer footer-bar-active footer-bar-align-right">
        <div id="footer-widgets" className="footer-widgets">
          <div className="footer-widgets-container grid-container">
            <div className="inside-footer-widgets">
              <div className="">
                <aside
                  id="block-24"
                  className="widget inner-padding widget_blocks"
                >
                  <p>
                    <iframe
                      title="Google Maps DK Audio Visual Services"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2501.319757859152!2d-0.15983838350894972!3d51.1763291795821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487606406fb7d14d%3A0x1ec56f6555fc694a!2sDK%20Audio%20Visual%20Services%20Ltd!5e0!3m2!1sen!2suk!4v1636291073521!5m2!1sen!2suk"
                      style={{ border: "0" }}
                      allowFullScreen
                      loading="lazy"
                      width="700"
                      height="300"
                    ></iframe>
                  </p>
                </aside>
              </div>
              <div className=""></div>
              <div className="">
                <aside
                  id="block-21"
                  className="widget inner-padding widget_blocks"
                >
                  <h2 className="has-text-align-left">Where to Find Us</h2>
                </aside>
                <aside id="block-22" className="widget inner-padding">
                  <p>
                    DK Audio Visual Services
                    <br />
                    Unit 17 Gatwick Metro Centre
                    <br />
                    Balcombe Road
                    <br />
                    Horley
                    <br />
                    Surrey
                    <br />
                    RH6 9GA
                  </p>
                </aside>
                <aside id="block-23" className="widget inner-padding">
                  <p>Tel: 01293 406854</p>
                </aside>
              </div>
            </div>
          </div>
        </div>
        <footer
          className="site-info"
          aria-label="Site"
          itemType="https://schema.org/WPFooter"
          itemScope
        >
          <div
            className="inside-site-info grid-container"
            style={{ padding: "20px" }}
          >
            <div className="footer-bar">
              <aside
                id="nav_menu-7"
                className="widget inner_padding widget_nav_menu"
              >
                <div className="menu-footer-menu-container">
                  <ul id="menu-footer-menu" className="menu">
                    <li id="menu-item-228" className="menu-item">
                      <a
                        href="https://www.dkavs.co.uk/privacy-policy"
                        style={{ color: "#fff" }}
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li id="menu-item-255" className="menu-item">
                      <a
                        href="https://www.dkavs.co.uk/terms-conditions"
                        style={{ color: "#fff" }}
                      >
                        Terms &#038; Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
            <div className="copyright-bar">
              <span className="copyright">&copy; 2021 DK AVS Ltd</span>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default RepairHomePage;
