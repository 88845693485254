import React, { useState } from "react";

const HolidayDiary = () => {
  return (
    <>
      <h6 className="">
        <strong>Monday, 12 December 2021</strong>
      </h6>
      <hr></hr>
      <p className="center">Day Off</p>

      <h6 className="">
        <strong>Tuesday, 13 December 2021</strong>
      </h6>
      <hr></hr>
      <p className="center">Day Off</p>

      <h6 className="">
        <strong>Tuesday, 13 December 2021</strong>
      </h6>
      <hr></hr>
      <p className="center">Day Off</p>

      <h6 className="">
        <strong>Tuesday, 13 December 2021</strong>
      </h6>
      <hr></hr>
      <p className="center">Day Off</p>
    </>
  );
};

export default HolidayDiary;
