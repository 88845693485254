import React, { useState } from "react";

const HolidayStatus = () => {
  return (
    <>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Application Date</th>
            <th scope="col">Application Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>9 December 2021</td>
            <td>Rejected</td>
          </tr>
          <tr>
            <td>10 December 2021</td>
            <td>Appealed</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default HolidayStatus;
