import React, { useEffect, useState } from "react";
import { Calendar, Check, FileText, X } from "react-feather";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as Gravatar from "gravatar";

import Avatar from "../img/SuperBaseBlack.jpg";
import Popup from "../_components/Popup";
import "./settings.css";

function Settings() {
  const [avatar, setAvatar] = useState();
  const [displayName, setDisplayName] = useState("");
  const [fullName, setFullName] = useState("");
  const [pronouns, setPronouns] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [verified, setVerified] = useState(false);
  const [receiveNotifications, setReceiveNotifications] = useState(false);
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(true);
  const [message, setMessage] = useState("");
  const [hideAvatar, setHideAvatar] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const removeImage = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to remove your profile picture")) {
      const gravatar = Gravatar.url(email, {
        s: 200,
        format: "https",
        d: "identicon",
      });
      setAvatar(gravatar);

      // TODO: Send to server
      setHideAvatar(true);
    }
  };

  const inputChange = (e) => {
    if (e.target.id === "notification") {
      setReceiveNotifications(!receiveNotifications);
    } else if (e.target.id === "emails") {
      setReceiveEmail(!receiveEmail);
    }
    setSaveEnabled(false);
  };

  const avatarUpload = (event) => {
    console.log(event.target.files);
    setAvatar(event.target.files[0]);
  };

  const saveChanges = (e) => {
    setMessage("Saved Changes");
  };

  useEffect(() => {
    if (Avatar) {
      setAvatar(Avatar);
      setHideAvatar(false);
    } else {
      setAvatar(
        Gravatar.url(email, { s: 200, format: "https", d: "identicon" })
      );
      setHideAvatar(true);
    }
    setDisplayName("Nikolaus");
    setFullName("Nikolaus Zolnhofer");
    setPronouns("He/Him");
    setEmail("nikolaus@superbase.com");
    setPhoneNo("01632 960013");
    setVerified(true);
    setReceiveNotifications(true);
    setReceiveEmail(false);
  }, [email]);

  return (
    <div className="px-4 py-5">
      <div className="row col-12 center">
        <div className="center text-center">
          <h1>Settings</h1>
        </div>
        <hr className="my-4" />
      </div>
      <div>
        <div className="py-3">
          <h2>Account Settings</h2>
        </div>

        <div className="row p-2">
          <div>
            <img src={avatar} className="main-avatar" alt="User Profile" />
            <h5 className="mt-0">
              <label htmlFor="profile">Upload New Avatar</label>{" "}
            </h5>
            <div className="my-3">
              <input
                type="file"
                name="profile"
                id="profile"
                aria-label="Choose File"
                accept="image/*"
                onChange={avatarUpload}
              />
            </div>
            <p className="text-gray-500">
              The maximum file size allowed is 200KB.
            </p>
            <button
              className="btn btn-outline-danger image-button"
              hidden={hideAvatar}
              onClick={removeImage}
            >
              Remove
            </button>
          </div>
        </div>

        {/* <hr className="my-4" />
        <div className="py-3">
          <h5>Time Settings</h5>
          <div className="dropdown show">
            
              <span className="dropdown-toggle-text ">[UTC + 1] Edinburgh</span>
              <svg
                className="s16 dropdown-menu-toggle-icon gl-top-3"
                data-testid="chevron-down-icon"
              ></svg>
            <div
              className="dropdown-menu dropdown-select show"
              x-placement="top-start"
            >
              {/* <div className="dropdown-input">
                <input
                  type="search"
                  data-qa-selector="dropdown_input_field"
                  className="dropdown-input-field"
                  placeholder="Filter"
                  autocomplete="off"
                ></input>
                <svg
                  className="s16 dropdown-input-search"
                  data-testid="search-icon"
                ></svg>
                <svg
                  className="s16 dropdown-input-clear js-dropdown-input-clear"
                  data-testid="close-icon"
                ></svg>
              </div> 
              <div
                data-qa-selector="dropdown_list_content"
                className="dropdown-content"
                aria-labelledby="dropdownMenuLink"
              >
                <select>
                  <option href="#" className="is-active">
                    [UTC - 12] International Date Line West
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 11] American Samoa
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 11] Midway Island
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 10] Hawaii
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 8] Alaska
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 7] Pacific Time (US &amp; Canada)
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 7] Tijuana
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 7] Arizona
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 6] Chihuahua
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 6] Mazatlan
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 6] Mountain Time (US &amp; Canada)
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 6] Central America
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 5] Central Time (US &amp; Canada)
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 5] Guadalajara
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 5] Mexico City
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 5] Monterrey
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 6] Saskatchewan
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 5] Bogota
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 4] Eastern Time (US &amp; Canada)
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 4] Indiana (East)
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 5] Lima
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 5] Quito
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 3] Atlantic Time (Canada)
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 4] Caracas
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 4] Georgetown
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 4] La Paz
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 4] Puerto Rico
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 3] Santiago
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 2.5] Newfoundland
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 3] Brasilia
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 3] Buenos Aires
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 2] Greenland
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 3] Montevideo
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 2] Mid-Atlantic
                  </option>

                  <option href="#" className="is-active">
                    [UTC 0] Azores
                  </option>

                  <option href="#" className="is-active">
                    [UTC - 1] Cape Verde Is.
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 1] Edinburgh
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 1] Lisbon
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 1] London
                  </option>

                  <option href="#" className="is-active">
                    [UTC 0] Monrovia
                  </option>

                  <option href="#" className="is-active">
                    [UTC 0] UTC
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Amsterdam
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Belgrade
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Berlin
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Bern
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Bratislava
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Brussels
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Budapest
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 1] Casablanca
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Copenhagen
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 1] Dublin
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Ljubljana
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Madrid
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Paris
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Prague
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Rome
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Sarajevo
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Skopje
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Stockholm
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Vienna
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Warsaw
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 1] West Central Africa
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Zagreb
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Zurich
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Athens
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Bucharest
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Cairo
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Harare
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Helsinki
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Jerusalem
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Kaliningrad
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Kyiv
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 2] Pretoria
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Riga
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Sofia
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Tallinn
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Vilnius
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Baghdad
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Istanbul
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Kuwait
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Minsk
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Moscow
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Nairobi
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Riyadh
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] St. Petersburg
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3] Volgograd
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 3.5] Tehran
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 4] Abu Dhabi
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 4] Baku
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 4] Muscat
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 4] Samara
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 4] Tbilisi
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 4] Yerevan
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 4.5] Kabul
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 5] Ekaterinburg
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 5] Islamabad
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 5] Karachi
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 5] Tashkent
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 5.5] Chennai
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 5.5] Kolkata
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 5.5] Mumbai
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 5.5] New Delhi
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 5.5] Sri Jayawardenepura
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 5.75] Kathmandu
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 6] Almaty
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 6] Astana
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 6] Dhaka
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 6] Urumqi
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 6.5] Rangoon
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 7] Bangkok
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 7] Hanoi
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 7] Jakarta
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 7] Krasnoyarsk
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 7] Novosibirsk
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 8] Beijing
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 8] Chongqing
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 8] Hong Kong
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 8] Irkutsk
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 8] Kuala Lumpur
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 8] Perth
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 8] Singapore
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 8] Taipei
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 8] Ulaanbaatar
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 9] Osaka
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 9] Sapporo
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 9] Seoul
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 9] Tokyo
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 9] Yakutsk
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 10.5] Adelaide
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 9.5] Darwin
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 10] Brisbane
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 11] Canberra
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 10] Guam
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 11] Hobart
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 11] Melbourne
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 10] Port Moresby
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 11] Sydney
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 10] Vladivostok
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 11] Magadan
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 11] New Caledonia
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 11] Solomon Is.
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 11] Srednekolymsk
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 13] Auckland
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 12] Fiji
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 12] Kamchatka
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 12] Marshall Is.
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 13] Wellington
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 13.75] Chatham Is.
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 13] Nuku'alofa
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 13] Samoa
                  </option>

                  <option href="#" className="is-active">
                    [UTC + 13] Tokelau Is.
                  </option>
                </select> 
              </div>
            </div>
            <div className="dropdown-loading">
              <div className="gl-spinner-container">
                <span
                  className="gl-spinner gl-spinner-orange gl-spinner-md gl-mt-7"
                  aria-label="Loading"
                ></span>
              </div>
            </div>
          </div>
          
        </div>*/}

        <hr className="my-4" />

        <div className="p-2">
          <label htmlFor="displayName" className="form-label">
            Display Name
          </label>
          <input
            className="form-control"
            defaultValue={displayName}
            id="displayName"
            onChange={inputChange}
          ></input>
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            className="col-12 form-control"
            disabled
            value={email}
            readOnly
            id="email"
          ></input>
          {verified !== false ? (
            <p>
              <Check height="16px" />
              Verified
            </p>
          ) : (
            <p>
              <X height="16px" />
              Not Verified
            </p>
          )}

          <button
            id="passReset"
            type="button"
            className="btn btn-secondary"
            onClick={togglePopup}
          >
            Change Password
          </button>
        </div>

        <div className="p-2">
          <label htmlFor="fullName" className="form-label">
            Full Name
          </label>
          <input
            className="col-12 form-control"
            defaultValue={fullName}
            id="fullName"
            autoComplete="name"
            onChange={inputChange}
          ></input>
          <label htmlFor="pronouns" className="form-label">
            Pronouns
          </label>
          <input
            className="col-12 form-control"
            defaultValue={pronouns}
            id="pronouns"
            onChange={inputChange}
          ></input>
          <label htmlFor="phoneNo" className="form-label">
            Phone Number
          </label>
          <input
            className="col-12 form-control"
            defaultValue={phoneNo}
            id="phoneNo"
            name="phoneNo"
            type="tel"
            autoComplete="tel"
            onChange={inputChange}
          ></input>
        </div>
        <hr className="my-4" />
      </div>

      <div>
        <div className="py-3">
          <h4>Notifications</h4>
        </div>

        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="notification"
            checked={receiveNotifications}
            onChange={inputChange}
          />
          <label className="form-check-label" htmlFor="notification">
            Receive Notifications
          </label>
        </div>

        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="emails"
            name="emails"
            checked={receiveEmail}
            onChange={inputChange}
          />
          <label className="form-check-label" htmlFor="emails">
            Receive Emails
          </label>
        </div>

        <hr className="my-4" />
      </div>

      <div>
        <div className="py-3">
          <h4>Other Settings</h4>
        </div>

        <div className="">
          <p className="mb-4">
            <a href="#/calendar" className="link">
              <Calendar />
              <span className="ml">Calendar</span>
            </a>
          </p>
          <p>
            <a href="#/files" className="link">
              <FileText />
              <span className="ml">Files</span>
            </a>
          </p>
        </div>
        <hr className="my-4" />
      </div>

      <button
        className="btn btn-light image-button mt-2"
        disabled={saveEnabled}
        onClick={saveChanges}
        id="saveNotification"
      >
        Save Changes
      </button>
      {message && <>Saved changes</>}

      {isOpen && (
        <Popup
          id="passReset"
          content={
            <Formik
              initialValues={{
                password: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={Yup.object({
                password: Yup.string().required("Required"),
                newPassword: Yup.string()
                  .required("Required")
                  .min(8, "Password is too short - 8 chars minimum")
                  .notOneOf([Yup.ref("password")], "Cannot reuse old password"),
                confirmPassword: Yup.string().oneOf(
                  [Yup.ref("newPassword"), null],
                  "Passwords must match"
                ),
              })}
              onSubmit={async (values, actions) => {
                console.log(values);
              }}
            >
              <Form className="container">
                <h4>Change Password</h4>
                <div className="p-2">
                  <label htmlFor="password" className="form-label">
                    Current Password
                  </label>
                  <Field
                    name="current-password"
                    id="password"
                    autoComplete="current-password"
                    type="password"
                    className="col-12 form-control"
                  />
                  <ErrorMessage
                    component="div"
                    className="text-danger"
                    name="password"
                  />
                  <label htmlFor="newPassword" className="form-label">
                    New Password
                  </label>
                  <Field
                    name="new-password"
                    id="newPassword"
                    autoComplete="new-password"
                    type="password"
                    className="col-12 form-control"
                  />
                  <ErrorMessage
                    component="div"
                    className="text-danger"
                    name="newPassword"
                  />
                  <label htmlFor="confirmPassword" className="form-label mt-2">
                    Confirm Password
                  </label>
                  <Field
                    name="new-password-2"
                    id="confirmPassword"
                    autoComplete="new-password"
                    type="password"
                    className="col-12 form-control"
                  />
                  <ErrorMessage
                    component="div"
                    className="text-danger"
                    name="confirmPassword"
                  />
                </div>
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
                <button className="btn btn-tertiary" onClick={togglePopup}>
                  Cancel
                </button>
              </Form>
            </Formik>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
}

export default Settings;
