import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const Reset = (props) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const search = useLocation().search;
  const session_id = new URLSearchParams(search).get("session-id");

  return (
    <div className="container center">
      <h2>Reset Password</h2>
      <Formik
        initialValues={{
          oldpassword: "",
          password: "",
          password2: "",
        }}
        validationSchema={Yup.object({
          oldpassword: Yup.string().required("This field is required"),
          password: Yup.string().required("This field is required"),
          password2: Yup.string().when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "Both password need to be the same"
            ),
          }),
        })}
        onSubmit={async (values) => {
          fetch(`${API_URL}/update-password`, {
            method: "POST",
            body: JSON.stringify({
              "old-password": values.oldpassword,
              password: values.password,
              password2: values.password2,
            }),
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Accept: "application/json; charset=UTF-8",
              Connection: "close",
              "session-id": session_id,
            },
          }).then((result) => {
            if (result.status === 201) {
              return result.json().then((json) => {
                props.history.push("/login");
              });
            } else {
              setIsError(true);
              setErrorMsg(result.json()["error-message"]);
            }
          });
        }}
      >
        <Form className="form-signin">
          <label htmlFor="current-password">Enter Old Password</label>
          <Field
            name="current-password"
            autocomplete="current-password"
            id="current-password"
            className="form-control"
            type="password"
            placeholder=""
            required
            aria-required
            enterKeyHint="next"
          />
          <ErrorMessage
            component="div"
            className="text-danger"
            name="current-password"
          />

          <label htmlFor="new-password">Enter New Password</label>
          <Field
            name="new-password"
            autocomplete="new-password"
            id="new-password"
            className="form-control"
            type="password"
            placeholder=""
            required
            aria-required
            enterKeyHint="next"
          />
          <ErrorMessage
            component="div"
            className="text-danger"
            name="new-password"
          />

          <label htmlFor="new-password-2">Re-enter New Password</label>
          <Field
            name="new-password-2"
            autocomplete="new-password"
            id="new-password-2"
            className="form-control"
            type="password"
            placeholder=""
            required
            aria-required
            enterKeyHint="done"
          />
          <ErrorMessage
            component="div"
            className="text-danger"
            name="new-password-2"
          />

          <br></br>
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
          {isError && <p>{errorMsg}</p>}
        </Form>
      </Formik>
    </div>
  );
};

export default Reset;
