import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const API_URL = process.env.REACT_APP_API_URL;

const TrackForm = () => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  return (
    <>
      <Formik
        initialValues={{
          policyNo: "",
          postCode: "",
        }}
        validationSchema={Yup.object({
          policyNo: Yup.string().required("Required"),
          postCode: Yup.string()
            .max(9, "Please enter a valid postcode")
            .required("A Post Code is Required"),
        })}
        onSubmit={async (values) => {
          fetch(`${API_URL}/jobs`, {
            method: "POST",
            body: JSON.stringify({
              "policy-no": values.policyNo
                .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                .replace(/(")/gm, "'"),
              "post-code": values.postCode
                .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                .replace(/(")/gm, "'"),
            }),
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Accept: "application/json; charset=UTF-8",
              Connection: "close",
              "session-id": JSON.parse(localStorage.getItem("currentUser"))[
                "session-id"
              ],
            },
          }).then((result) => {
            if (result.status === 201) {
              return result.json().then((json) => {});
            } else {
              setIsError(true);
              setErrorMsg(result.json()["error-message"]);
            }
          });
        }}
      >
        <Form style={{ textAlign: "left", display: "inline-block" }}>
          <fieldset className="row">
            <div className="col-12 col-md-8">
              <label htmlFor="policyNo">Job No. (*)</label>
              <Field
                name="policyNo"
                id="policyNo"
                className="form-control"
                placeholder=""
                type="text"
                aria-required
                required
                enterKeyHint="next"
              />
              <ErrorMessage
                component="div"
                className="text-danger"
                name="policyNo"
              />
            </div>
            <div className="col-12 col-md-8">
              <label htmlFor="postCode">Post Code (*)</label>
              <Field
                name="postCode"
                id="postCode"
                className="form-control"
                placeholder=""
                type="text"
                required
                enterKeyHint="done"
              />
              <ErrorMessage
                component="div"
                className="text-danger"
                name="postCode"
              />
            </div>
          </fieldset>

          <button
            className="mt-3 mb-3"
            style={{ minWidth: "10rem" }}
            type="submit"
          >
            Go
          </button>
        </Form>
      </Formik>
    </>
  );
};

export default TrackForm;
