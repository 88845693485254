import React from "react";

import "./dashboard.css";
import JobForm from "../_components/JobForm";

function RequestForm() {
  return (
    <>
      {/* Help Box Goes Here */}
      <div>
        <h2>Help</h2>
        <details>
          <summary>Can't Submit</summary>
          <ul>
            <li>Check all red outlined boxes are filled out.</li>
            <li>Post Codes need to be valid (less than 9 characters)</li>
            <li>Repair until has to be greater than £100</li>
          </ul>
        </details>
        <details>
          <summary>My Product Type is not listed</summary>
          <ul>
            <li>Please select 'Other' and list it in</li>
          </ul>
        </details>
        <details>
          <summary>My Manufacturer is not listed</summary>
          <ul>
            <li>Please select 'Other' and list it in</li>
          </ul>
        </details>
        <button className="btn btn-info">Feedback</button>
      </div>
      <JobForm />
    </>
  );
}

export default RequestForm;
